<template>
  <div class="container-fluid mt-5">
    <div class="row my-3 justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="pl-3 pl-xl-0 h3 text-muted d-none d-lg-block">Wir suchen Dich!</div>
        <div class="h1 d-none d-lg-block company-color1">Festanstellung / Ausbildung</div>
        <div class="h1 d-lg-none company-color1">Werde Fahrlehrer</div>
        <div>
          Zur Verstärkung unseres Teams suchen wir ab sofort einen Fahrlehrer/in (m/w/d) in Vollzeit für die theoretische und praktische Ausbildung, insbesondere für die Führerscheinklasse B (Auto). <br />
          Wir bilden auch aus! Sprich uns dazu gerne an.
          <br />
          Wenn du bereits Fahrlehrer bist bieten wir dir einen unbefristeten Arbeitsvertrag mit einem interessanten Gehalt.
          <br />
          Außerdem unterstützen wir dich garantiert in deiner Aus- / Weiterbildung.
          <br />
          <br />
          <span class="font-weight-bold"><u>Was du mitbringen solltest:</u></span> 
          <ul>
              <li>selbständige und zuverlässige Arbeitsweise</li>
              <li>guter Umgang mit Kunden und im Team</li>
          </ul>
          <span class="font-weight-bold"><u>Das bieten wir:</u></span>
          <ul>
              <li>einen unbefristeten Arbeitsvertrag</li>
              <li>faire, branchenüberdurchschnittliche Bezahlung</li>
              <li>strukturierte und familiäre Arbeitsumgebung</li>
          </ul>     
          Bitte bewerben Sie sich postalisch, per E-Mail oder telefonisch in unserer Fahrschule.
          <hr />
          <div class="text-muted ml-1 pl-2 mt-2 border-left border-secondary">
            <span class="text-dark">Dirk Werner</span><br />
            Fahrschule Werner<br />
            Ratzelstraße 188<br />
            04207 Leipzig<br />
            <a href="mailto:info@fahrschule-werner.de">info@fahrschule-werner.de</a><br />
            0341 / 4299991<br />
          </div>       
        </div>
      </div>
    </div>
    <div class="row my-3 justify-content-center">
      <div class="col-10 text-muted mb-3">
        <small>- Dein Team der Fahrschule Werner</small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "job",
};
</script>

